
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
/** Tips 共通樣式
 *  @rules 傳遞顯示字串
 */

@Options({})
export default class Tips extends Vue {
  @Prop()
  rules!: string[];
}
