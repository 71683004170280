<template>
  <div class="tips-container">
    <i class="el-icon-warning"></i>
    <span class="u_m--l5">{{ $t('ui_prompt') }}：</span>
    <ol style="list-style:decimal;" class="u_fw--bold u_w--100p list-detail" key="group">
      <template v-for="(rule, id) in rules">
        <li :key="`${rule}_${id}`" v-if="rule" class="u_t--justify">
          {{ $t(rule) }}
        </li>
      </template>
    </ol>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
/** Tips 共通樣式
 *  @rules 傳遞顯示字串
 */

@Options({})
export default class Tips extends Vue {
  @Prop()
  rules!: string[];
}
</script>
<style lang="scss" scoped>
.tips-container {
  font-size: 14px;
  margin-top: 16px /* 16/25 */;
  @extend .text-tomato;
}
.list-detail {
  padding: 6px /* 6/25 */ 12px /* 12/25 */ 6px /* 6/25 */ 18px /* 18/25 */;
  margin-top: 8px /* 16/25 */;
}
.u_w--100p {
  width: 100%;
}
.u_t--justify {
  line-height: 1.5;
  text-align: justify;
}
.u_fw--bold {
  font-weight: bold;
}
.u_m--l5 {
  margin-left: 5px;
}
.u_m--l10 {
  margin-left: 10px;
}
</style>
