import doFetch from '../../fetch_mainland';

// 题目列表
const topicDeportList = async <T>(data: any) =>
  await doFetch<T>('/api/agent/getSubjectCateList', 'POST', data);

const topicList = async <T>(data: any) =>
  await doFetch<T>('/api/agent/getSubjectList', 'POST', data);

const getMemberPrizesList = async <T>(data: any) =>
  await doFetch<T>('/api/agent/getMemberPrizesList', 'POST', data);

export default {
  topicDeportList,
  topicList,
  getMemberPrizesList
};
