<template>
  <div class="u_m--t20">
    <custom-table
      :titleList="table.title"
      :list="table.list"
      :title-class="'background-th'"
      :table="table"
      :pagination="pagination"
      @change="handleChange"
      :loading="loading"
    >
      <template v-slot:default="{item: {key, item}}">
        <span v-if="key === 'name'">{{ formatLanguage(item.name) }}</span>
        <span v-if="key === 'operate'">
          <img src="@/assets/web/image/icons/copy.png" alt="" class="c-icons" @click="copy(item)" />
          <img
            src="@/assets/web/image/icons/detail.png"
            alt=""
            class="c-icons"
            @click="showDetail(item)"
          />
        </span>
      </template>
    </custom-table>
  </div>

  <el-dialog
    v-model="detailPopup.show"
    center
    :close-on-click-modal="false"
    width="600px"
    custom-class="c_dialog"
  >
    <template #title>
      <el-row :class="`u_p--b10 u_t--center title`" type="flex" align="middle" justify="center">
        <span>{{ formatLanguage(detailPopup.data.name) }}</span>
      </el-row>
    </template>
    <div class="dialog-content">
      <template v-for="(item, index) in detailPopup.data.data" :key="item.id">
        <div class="name">{{ index + 1 }}、{{ formatLanguage(item.subject) }}</div>
        <div class="options">
          <div v-for="(_item, _index) in item.options" class="item" :key="`${item.id}-${_index}`">
            {{ formatLanguage(_item) }}
          </div>
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ElMessage} from 'element-plus';
import Tips from '@/common/web/tips/index.vue';
import CustomTable from '@/common/web/table/index.vue';
import eventBus from '@/web/eventBus';

import {App, Format, Server} from '@/services';
import askSpread from '@/services/server/web/askSpread';

import {ITopicTable, ITopicTableResult} from '@/model/askSpread';
import {IPage} from '@/model/commission';
import {ISubdomainResult} from '@/model';
@Options({
  components: {
    Tips,
    CustomTable
  }
})
export default class Proxy extends Vue {
  config = App.getConfig() || {
    currency: {
      id: '',
      code: 'CNY',
      text: ''
    },
    dollarSign: '¥'
  };
  userinfo = App.getUserinfo();
  loading = false;
  table: any = {
    list: [],
    title: [
      {
        name: 'ui_name_1',
        key: 'name'
      },
      {
        name: 'rp_operate',
        key: 'operate'
      }
    ],
    keys: ['name', 'operate']
  };
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  detailPopup = {
    show: false,
    data: {}
  };
  proxyUrl = null;

  mounted() {
    this.getProxyUrl();
    this.getList();
    eventBus.bus$on('langChange', () => {
      this.getList();
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  formatLanguage(text: string[]) {
    if (!text) return '';
    const language = localStorage.getItem('language') || 'zh-CN';
    const languages: {
      [key: string]: number;
    } = {
      en: 0,
      'zh-CN': 1,
      th: 2,
      vn: 3
    };
    const languageIndex = languages[language];
    return text[languageIndex] || '';
  }
  handleChange(item: {limit: number; page: number}) {
    this.pagination.page = item.page;
    this.pagination.pageLimit = item.limit;
    this.getList();
  }
  async getProxyUrl() {
    const res = await Server.Web.Operation.linkMember<ISubdomainResult>();
    if (!(res instanceof Error)) {
      this.proxyUrl = res.list[0]?.domain;
    }
  }
  async getList() {
    this.loading = true;
    const data = await askSpread.topicDeportList<ITopicTableResult>({
      page: this.pagination.page,
      size: this.pagination.pageLimit
    });
    this.loading = false;
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.pagination = {
        ...this.pagination,
        totalNumber: data.total,
        totalPage: Math.ceil(data.total / this.pagination.pageLimit)
      };
      this.table.list = data.data;
    }
  }
  async showDetail(item: ITopicTable) {
    this.loading = true;
    const data = await askSpread.topicList<ITopicTableResult>({
      page: 1,
      size: 99999,
      category_id: item.id
    });
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.detailPopup = {
        show: true,
        data: {
          ...item,
          data: data.data
        }
      };
    }
    this.loading = false;
  }
  copy(item: ITopicTable) {
    const baseUrl =
      this.config.dollarSign === '฿' ? 'http://www.aia518.com' : 'http://www.aia1111.com';
    const url = `${this.proxyUrl ? `https://${this.proxyUrl}` : baseUrl}/m/question/${item.id}`;
    Format.copyTextToClipboard(
      `${this.formatLanguage(item.name as any)} ${this.$t('immediatelyAnswer')} ${url}`
    );
    ElMessage(this.$t('ui_successful_copy'));
  }
}
</script>

<style lang="scss" scoped>
.c-icons {
  margin: 0 15px;
  display: inline-block;
  width: 16px;
  height: 16px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
.name {
  color: #222;
  font-size: 16px;
}
.options {
  display: flex;
  margin-bottom: 20px;
  .item {
    padding: 12px;
    margin: 20px 0 0 13px;
    flex: 1;
    background: #f6f7f8;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    color: #999;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
