
import {Options, Vue} from 'vue-class-component';
import {ElMessage} from 'element-plus';
import Tips from '@/common/web/tips/index.vue';
import CustomTable from '@/common/web/table/index.vue';
import eventBus from '@/web/eventBus';

import {App} from '@/services';
import askSpread from '@/services/server/web/askSpread';

import {IMemberResult} from '@/model/askSpread';
import {IPage} from '@/model/commission';
@Options({
  components: {
    Tips,
    CustomTable
  }
})
export default class Proxy extends Vue {
  config = App.getConfig() || {
    currency: {
      id: '',
      code: 'CNY',
      text: ''
    },
    dollarSign: '¥'
  };
  userinfo = App.getUserinfo();
  loading = false;
  table: any = {
    list: [],
    title: [
      {
        name: 'rp_acc',
        key: 'username'
      },
      {
        name: 'ui_date',
        key: 'created_at'
      },
      {
        name: 'prize',
        key: 'prize'
      }
    ],
    keys: ['username', 'created_at', 'prize']
  };
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };

  mounted() {
    this.getList();
    eventBus.bus$on('langChange', () => {
      this.getList();
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  formatLanguage(text: string[]) {
    if (!text) return '';
    const language = localStorage.getItem('language') || 'zh-CN';
    const languages: {
      [key: string]: number;
    } = {
      en: 0,
      'zh-CN': 1,
      th: 2,
      vn: 3
    };
    const languageIndex = languages[language];
    return text[languageIndex] || '';
  }
  handleChange(item: {limit: number; page: number}) {
    this.pagination.page = item.page;
    this.pagination.pageLimit = item.limit;
    this.getList();
  }
  async getList() {
    this.loading = true;
    const data = await askSpread.getMemberPrizesList<IMemberResult>({
      page: this.pagination.page,
      size: this.pagination.pageLimit,
      agent_id: this?.userinfo?.userId
    });
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.pagination = {
        ...this.pagination,
        totalNumber: data.total,
        totalPage: Math.ceil(data.total / this.pagination.pageLimit)
      };
      this.table.list = data.data;
    }
    this.loading = false;
  }
}
